<script>
import { UserRoleType } from '@/utils/enums/UserRoleType';
import { mapActions, mapState } from 'vuex';

export default {
  data: () => ({
    userRoleType: UserRoleType,
    form: {
      email: '',
      password: null,
    },

    showPass: false,
    loading: false,
  }),

  computed: {
    ...mapState('auth', {
      currentUser: (state) => state.user,
    }),
  },

  methods: {
    ...mapActions('auth', ['login']),

    async submit() {
      this.loading = true;
      try {
        await this.$store.dispatch('auth/login', this.form);
        if (this.currentUser.role === 'STUDENT_RESPONSIBLE') {
          this.$router.push('/');
        } else {
          this.$router.push('/live-cam');
        }
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },

    clear() {
      this.form = {
        email: '',
        password: null,
      };
      this.$refs.form.reset();
    },
  },
};
</script>

<template>
  <div class="px-5 px-sm-12">
    <div>
      <h1 class="text-center">Bem vindo de volta!</h1>
      <h3 class="text-center mt-3">Faça login para ter acesso a sua conta</h3>

      <v-form ref="form" class="mt-12" @submit.prevent="submit">
        <v-text-field
          v-model="form.email"
          prepend-inner-icon="mdi-account-outline"
          label="Email"
          outlined
          persistent-placeholder
        />
        <v-text-field
          v-model="form.password"
          label="Senha"
          prepend-inner-icon="mdi-lock-outline"
          :append-icon="showPass ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
          :type="showPass ? 'text' : 'password'"
          outlined
          persistent-placeholder
          hide-details
          @click:append="showPass = !showPass"
        />
        <div class="text-right mt-3">
          <router-link to="/forgot-password">Esqueceu sua senha?</router-link>
        </div>
        <div class="text-center mt-8">
          <v-btn type="submit" x-large block color="primary" :loading="loading"> Entrar </v-btn>
        </div>
      </v-form>

      <div class="pt-15 text-center">Não tem conta? <router-link to="/register">Cadastre-se</router-link></div>
    </div>
  </div>
</template>
